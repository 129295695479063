<script>
import {
  required,
  /*email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,*/
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import Switches from "vue-switches";
// import Multiselect from "vue-multiselect";
//import NumberInputSpinner from "vue-number-input-spinner";
/*import useNetw from "@useNetw";
import Swal from "sweetalert2";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    /*DatePicker,
    Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: "สร้างผู้แทนจำหน่าย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
    //
  },

  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 50);
  },

  created() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    console.log(user);
  },

  data() {
    return {
      submitformDealer: false,

      title: "สร้างผู้แทนจำหน่าย",
      items: [
        {
          text: "Dealer",
          href: "/",
        },
        {
          text: "formDealer",
          active: true,
        },
      ],

      loading: undefined,
      options: [],
      dataTest: [],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formdealer: {
        
        dealerNameTH: "", //สาขาต้นสังกัด ไทย  เต็ม
        dealerShortNameTH: "", // สาขาต้นสังกัด ไทย ย่อ
        dealerNameEN: "", //สาขาต้นสังกัด อังกฤษ  เต็ม
        dealerShortNameEN: "", // สาขาต้นสังกัด อังกฤษ ย่อ
      },
    };
  },
  validations: {
    formdealer: {
      
      dealerNameTH: {
        required,
      },
      dealerShortNameTH: {
        
      },
      dealerNameEN: {
        
      },
      dealerShortNameEN: {
        
      },
    },
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    postDealer: function() {
      this.loading = true;
      const DealerFormData = new FormData();
      
      DealerFormData.append("nameTh", this.formdealer.dealerNameTH);
      DealerFormData.append("shortNameTh", this.formdealer.dealerShortNameTH);
      DealerFormData.append("nameEn", this.formdealer.dealerNameEN);
      DealerFormData.append("shortNameEn", this.formdealer.dealerShortNameEN);

      useNetw
        .post("api/central/dealer/store", DealerFormData)
        .then((response) => {
          this.dealerIdPost = response.data.dealerId;

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          console.log(this.dealerIdPost);
          this.loading = false;
          this.$router.push({ name: "mainDealer" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    
    

    formDealer() {
      // this.submitformDealer = true;
      this.$v.$touch();
      if (this.$v.formdealer.$invalid) {
        console.log("false");
        this.submitformDealer = true;
        
      } else {
          this.postDealer();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <form class="needs-validation" @submit.prevent="formDealer">
              <div class="card-body">
                <div class="row">
                <div class="col-md-6">
                    <h4 class="card-title">เพิ่มผู้แทนจำหน่าย</h4>
                </div>
                <div class="col-md-6 text-end">
                <router-link :to="{ name: 'mainDealer' }">
                    <button class="btn btn-secondary">
                      กลับสู่หน้าหลัก
                    </button>
                  </router-link>
                  </div>
                  </div>
                
                <hr />

                <div class="row">
                  <!-- <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="dealerId">รหัสสาขา</label>
                      <input
                        v-model="formdealer.dealerId"
                        type="text"
                        class="form-control"
                        placeholder="รหัสสาขา"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.dealerId.$error,
                        }"
                      />
                      <div
                        v-if="submitformDealer && $v.formdealer.dealerId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.dealerId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-md-6">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="dealerNameTH">ชื่อสาขา (ไทย) </label>
                      <input
                        v-model="formdealer.dealerNameTH"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อสาขา (ไทย)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.dealerNameTH.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.dealerNameTH.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.dealerNameTH.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3 position-relative">
                      <label for="dealerNameEN">ชื่อสาขา (อังกฤษ) </label>
                      <input
                        v-model="formdealer.dealerNameEN"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อสาขา (อังกฤษ)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.dealerNameEN.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.dealerNameEN.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.dealerNameEN.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--//! End Rows -->

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3 position-relative" style="width:60%">
                      <label for="dealerShortNameTH">ชื่อย่อสาขา (ไทย)</label>
                      <input
                        v-model="formdealer.dealerShortNameTH"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อย่อสาขาย่อย (อังกฤษ)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.dealerShortNameTH.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer &&
                          $v.formdealer.dealerShortNameTH.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="!$v.formdealer.dealerShortNameTH.required"
                          >{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3 position-relative" style="width:60%">
                      <label for="dealerShortNameEN"
                        >ชื่อย่อสาขา (อังกฤษ)</label
                      >
                      <input
                        v-model="formdealer.dealerShortNameEN"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อย่อสาขาย่อย (อังกฤษ)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.dealerShortNameEN.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer &&
                          $v.formdealer.dealerShortNameEN.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="!$v.formdealer.dealerShortNameEN.required"
                          >{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //!End row -->

                <hr />

                <div>
                  <button type="submit" class="btn btn-primary" >บันทึก</button>
                </div>
              </div>
            </form>
          </div>
          <!-- //!end card -->
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
